.font-red-hat {
  font-family: "Red Hat Display", sans-serif;
}

.registro-component {
  background-color: #585dcc;
  min-height: 100vh;
  .registro-container {
    height: 100vh;

    .registro-left {
      position: relative;
      @media (max-width: 800px) {
        min-height: 42vh;
        text-align: center;
        margin: 5vh auto 3vh;
      }
      .logo-recibir {
        max-width: 40%;
        position: absolute;
        top: 10%;
        left: 0%;
        @media (max-width: 800px) {
          top: 8%;
          left: 0%;
        }
      }
      .icon-int {
        margin-bottom: 1em;
        width: 15%;
        @media (max-width: 800px) {
          width: 19%;
          margin-bottom: 0em;
        }
      }
      .carousel-conti {
        width: 100%;
      }
      .btn-text {
        bottom: 4em;
        position: absolute;
        @media (max-width: 800px) {
          position: relative;
          margin-top: 2em;
          bottom: auto;
        }
      }
    }
    .registro-left-22 {
      @media (max-width: 800px) {
        margin: 9vh auto 3vh;
      }
    }
    .registro-right {
      background-color: #ffffff;
      position: relative;
      padding-top: 5vh;
      .Iframe-form {
        width: 70%;
        height: 65vh;
        margin: 0 auto;
        border: none;
        border-radius: 4px;
        @media (max-width: 800px) {
          width: 100%;
          height: 80vh;
        }
      }
      .Social-columns {
        max-width: 30%;
        margin: 2em auto 0em;
        @media (max-width: 700px) {
          margin: 1.5em auto;
          max-width: 35%;
        }
        img {
          max-width: 90%;
          margin: 0 auto;
        }
      }
    }
    .registro-right-22 {
      @media (max-width: 800px) {
        padding-top: 0vh;
      }
    }
    .alineacionmobile {
      @media (max-width: 700px) {
        min-height: 60vh;
      }
    }

    .alineacionmobile2 {
      @media (max-width: 700px) {
        min-height: 32vh;
      }
    }
    .alineacionmobile222 {
      @media (max-width: 700px) {
        min-height: 28vh;
      }
    }
  }
}

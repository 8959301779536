
$azul-rey: #2a2fab;
$azul-bajo: #432a95;
$morado: #682477;
$rosa: #77216b;
$backgroundfooter: linear-gradient(270deg, #77216b 17.46%, #2a2fa9 76.78%);
$breakpoint-tablet: 1025px;
$breakpoint-movil: 480px;
$roboto: "Roboto";

.container-footer {
    background-color: #2a2ea900;
}

html,
body {
  height: 100%;
  margin: 0;
}

.home-component {
  background-color: #585dcc;
  background-image: url("../../Img/Backkground_Purple.png");
  min-height: 100vh !important;
  @media (max-width: 800px) {
    padding-top: 7vh;
  }
  .Columns-first {
    margin: 1em 0 0em;
    @media (max-width: 600px) {
      margin: 0;
    }
    .logo {
      max-width: 35%;
      @media (max-width: 600px) {
        max-width: 90%;
      }
    }
  }

  .Left-Container {
    position: relative;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .img-back {
      max-width: 78%;
      position: absolute;
      z-index: 0;
      animation: spin 40s linear infinite;
      @media (max-width: 700px) {
        max-width: 83%;
      }
    }
    .img-front {
      z-index: 1;
      position: relative;
      max-width: 77%;
      @media (max-width: 700px) {
        max-width: 83%;
      }
    }
  }
  .Left-Container-2 {
    position: relative;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .img-back {
      max-width: 80%;
      position: absolute;
      z-index: 0;
      animation: spin 40s linear infinite;
    }
    .img-front-circle {
      z-index: 1;
      position: relative;
      max-width: 90%;
      margin: 0 auto;
    }
  }
  .bottom-function {
    margin-top: 1em;
    padding: 1em 0 1em 0;
    @media (max-width: 700px) {
      margin-top: 2em;
    }
    svg {
      max-width: 90%;
    }
    .hideonmobile {
      @media (max-width: 700px) {
        display: none;
      }
    }
  }

  @keyframes spin {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
}

.home-component-modified {
  @media (max-width: 800px) {
    padding-top: 0vh;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
input {
  font-family: "Roboto", sans-serif;
  border: none;
  font-size: 14px;
  color: black;
  font-weight: 500;
  &:focus {
    outline: none;
  }
}
input::placeholder {
  color: #afb8bc;
}

.fontRedHat {
  font-family: "Red Hat Display", sans-serif;
}

.homeindex {
  min-height: 100vh !important;
  .flexed-columns {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;

    .sin-mensualidades {
      margin-top: 3vh;
    }
    .potencializa {
      font-size: 3.6vh;
      line-height: 1vh;
      text-transform: uppercase;
      margin-top: 0vh;
      span {
        display: block;
        white-space: nowrap;
        border-right: 4px solid;
        width: 13ch;
        animation:
          typing 3.5s infinite steps(12),
          blink 0.8s infinite step-end alternate;
        overflow: hidden;
      }
      @keyframes typing {
        from {
          width: 0;
        }
      }
      @keyframes blink {
        50% {
          border-color: transparent;
        }
      }
    }
    .tunegocio {
      font-size: 8vh;
      line-height: 7.9vh;
      margin-bottom: 4vh;
      text-align: center;
      @media (max-width: 700px) {
        font-size: 4.5vh;
        margin: 0vh auto !important;
        line-height: 5.5vh;
      }
    }
    .text-light {
      font-weight: 300;
      margin-bottom: 2vh;
      text-align: center;
      @media (max-width: 700px) {
        margin: 1em !important;
        font-size: 2vh;
        line-height: 2.5vh;
      }
    }
    .enlacebtn2 {
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .Botom-container-items {
    margin: 2em 0 3em;

    @media (max-width: 700px) {
      margin: 1em 0 0em;
    }
    .CONTAINER-CURSOR {
      text-decoration: none;
      .Item-Home {
        width: 100%;
        height: 100%;
        border-radius: 23px;
        border: 1px solid #fff;
        background: rgba(255, 255, 255, 0.4);
        backdrop-filter: blur(10px);
        padding: 24px 0;
        min-height: 175px;
        @media (max-width: 900px) {
          min-height: 293px;
          padding: 24px 0 20px;
        }
        .ind-columns {
          margin: 0 24px;
          @media (max-width: 700px) {
            margin: 0 auto;
          }
          img {
            max-width: 100%;
            @media (max-width: 800px) {
              max-height: 132px;
              margin: 0 auto;
            }
          }
          .text-cont1 {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            .title-titem {
              font-weight: 700;
              min-height: 2.5em;
              @media (max-width: 700px) {
                text-align: center;
                min-height: 4.5vh;
              }
            }
            .Links-Landings {
              width: 100%;
              text-decoration: none;
              .btn-links {
                margin: 0em 0 0em 0 !important;
              }
            }
          }
        }
      }
    }
  }
}
